exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-angrybirdsgo-js": () => import("./../../../src/pages/angrybirdsgo.js" /* webpackChunkName: "component---src-pages-angrybirdsgo-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-csr-2-js": () => import("./../../../src/pages/csr2.js" /* webpackChunkName: "component---src-pages-csr-2-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-football-js": () => import("./../../../src/pages/football.js" /* webpackChunkName: "component---src-pages-football-js" */),
  "component---src-pages-friendsonthemove-js": () => import("./../../../src/pages/friendsonthemove.js" /* webpackChunkName: "component---src-pages-friendsonthemove-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-hidden-js": () => import("./../../../src/pages/hidden.js" /* webpackChunkName: "component---src-pages-hidden-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moria-js": () => import("./../../../src/pages/moria.js" /* webpackChunkName: "component---src-pages-moria-js" */),
  "component---src-pages-rushnattack-js": () => import("./../../../src/pages/rushnattack.js" /* webpackChunkName: "component---src-pages-rushnattack-js" */),
  "component---src-pages-silenthill-js": () => import("./../../../src/pages/silenthill.js" /* webpackChunkName: "component---src-pages-silenthill-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-takecover-js": () => import("./../../../src/pages/takecover.js" /* webpackChunkName: "component---src-pages-takecover-js" */),
  "component---src-pages-vietcong-2-js": () => import("./../../../src/pages/vietcong2.js" /* webpackChunkName: "component---src-pages-vietcong-2-js" */)
}

